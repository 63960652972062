import React from 'react';
import PropTypes from 'prop-types';
import { Col, Typography } from 'antd';

const {
  Paragraph,
  Text,
} = Typography;

export const DropdownAttribute = ({ attr }) => (
  <Col flex="1 1 50%">
    <Paragraph>
      {attr.attribute.name}
      {' - '}
      {attr.values.map(({
        name,
        idx,
      }) => (
        <Text key={name + idx} strong>{name}</Text>
      ))}
    </Paragraph>
  </Col>
);

DropdownAttribute.propTypes = {
  attr: PropTypes.shape({
    attribute: PropTypes.shape({
      name: PropTypes.string,
      inputType: PropTypes.string,
    }),
    values: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }).isRequired,
};
