import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Typography } from 'antd';

const {
  Paragraph,
  Title,
} = Typography;

export const MultiselectAttribute = ({ attr }) => (
  <>
    <Title level={4}>
      {attr.attribute.name}
    </Title>
    <Paragraph>
      {attr.values.map(({
        name,
        idx,
      }) => (
        <Tag key={name + idx}>{name}</Tag>
      ))}
    </Paragraph>
  </>
);

MultiselectAttribute.propTypes = {
  attr: PropTypes.shape({
    attribute: PropTypes.shape({
      name: PropTypes.string,
      inputType: PropTypes.string,
    }),
    values: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }).isRequired,
};
