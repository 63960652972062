import React from 'react';
import { BaseLayout } from '../../components/layout/base/base-layout';
import { ProductTemplate } from './product-template';
import { SEO } from '../../components/layout/seo';

// eslint-disable-next-line react/prop-types
const ProductPageTemplate = ({ pageContext }) => (
  <BaseLayout>
    <SEO
      title={pageContext.seoTitle || pageContext.name}
      description={pageContext.seoDescription || pageContext.description}
    />
    <ProductTemplate pageContext={pageContext} />
  </BaseLayout>
);

export default ProductPageTemplate;
