import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Col, Divider, Row, Typography,
} from 'antd';
import { FiEdit } from 'react-icons/fi';
import { Map } from '../../components/map/map';
import css from './product-template.module.less';
import { ImageCarousel } from '../../components/carousel/carousel';
import { CarouselCard } from '../../components/cards/carousel-card/carousel-card';
import { separateAttributes } from './utils/separate-attributes';
import { AddToFavoritesButton } from '../../components/favorites/add-to-favourites-button/add-to-favourites-button';
import { PrivateComponent } from '../../components/auth/authorization/private-component';
import { Description } from '../../components/common/description';
import { PublicMetadataModal } from './public-metadata-modal';
import { inMemoryAuth } from '../../components/auth/in-memory-auth';

const {
  Title,
  Paragraph,
} = Typography;

export const ProductTemplate = ({ pageContext: product }) => {
  const summaryAttrNames = ['Bedrooms', 'Beds'];
  const [
    summaryAttr,
    multiselectAttr,
    dropdownAttr,
  ] = separateAttributes(product.attributes, summaryAttrNames);

  const variantName = product.productType.slug === 'venue' ? 'space' : 'variant';
  const variantCount = `${product.variants.length} 
  ${variantName}${product.variants.length === 1 ? '' : 's'}`;

  const addToFavourites = product.productType.hasVariants ? null
    : <AddToFavoritesButton variantId={product.defaultVariant ? product.defaultVariant.id : null} withText />;

  const actionContact = product.metadata.length > 0 ? <PublicMetadataModal metadata={product.metadata} /> : null;

  const actionEdit = inMemoryAuth.user.isStaff ? (
    <PrivateComponent isStaff permissions={['MANAGE_PRODUCTS']}>
      <a href={`${process.env.GATSBY_SALEOR_DASHBOARD_URL}products/${product.id}`}>
        <Button
          icon={<FiEdit />}
          size="large"
        >
          Edit in dashboard
        </Button>
      </a>
    </PrivateComponent>
  ) : null;

  const actions = [];

  if (actionContact) {
    actions.push(actionContact);
  }

  if (actionEdit) {
    actions.push(actionEdit);
  }

  if (addToFavourites) {
    actions.push(addToFavourites);
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <ImageCarousel
            images={product.images}
            isLarge
            preview
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[32, 32]} justify="center">
        <Col flex="0 1 550px">
          <Title level={2}>{product.name}</Title>
          <Description
            descriptionJson={product.descriptionJson}
            description={product.description}
          />
          {summaryAttr.length > 0 ? (
            <>
              <Divider />
              <Title level={2}>Summary</Title>
              <Row>
                {summaryAttr}
              </Row>
            </>
          ) : null}
          {dropdownAttr.length > 0 ? (
            <>
              <Divider />
              <Title level={2}>Details</Title>
              <Row>
                {dropdownAttr}
              </Row>
              <Divider />
            </>
          ) : null}
          {multiselectAttr}
        </Col>
        <Col flex="0 1 550px">
          <Card
            className={css.sidebar}
            actions={actions}
            title={product.location?.address?.city?.toUpperCase() || variantCount}
            extra={product.productType.hasVariants === false
              ? (
                <Paragraph copyable className={css.noSpacing}>
                  {product.variants.map(({ sku }) => (sku))}
                </Paragraph>
              )
              : null}
          >
            {product.location
              ? (<Map products={[product]} />)
              : null}
          </Card>
        </Col>
      </Row>
      {product.productType.hasVariants
        ? (
          <>
            <Row gutter={[32, 32]} justify="center">
              <Col flex="0 1 1100px">
                <Divider />
                <Title level={1} className="text-center">
                  {variantCount}
                </Title>
              </Col>
            </Row>
            <Row gutter={[32, 32]} justify="center">
              {product.variants.map(
                (variant) => (
                  <Col xs={24} md={12} xl={8} xxl={6} key={variant.id}>
                    <CarouselCard
                      variantId={variant.id}
                      attributes={variant.attributes}
                      images={variant.images}
                      productType={product.productType}
                      productLocation={product.location}
                    />
                  </Col>
                ),
              )}
            </Row>
          </>
        ) : null}
    </>
  );
};

ProductTemplate.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    attributes: PropTypes.arrayOf(PropTypes.shape({
      attribute: PropTypes.shape({
        name: PropTypes.string,
        inputType: PropTypes.string,
      }),
      values: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
    })),
    category: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    defaultVariant: PropTypes.shape({
      id: PropTypes.string,
    }),
    productType: PropTypes.shape({
      hasVariants: PropTypes.bool,
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    images: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
    })),
    location: PropTypes.shape({
      geography: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(PropTypes.number),
      }),
      address: PropTypes.shape({
        city: PropTypes.string,
        postalCode: PropTypes.string,
      }),
    }),
    variants: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      sku: PropTypes.string,
      attributes: PropTypes.arrayOf(PropTypes.shape({
        attribute: PropTypes.shape({
          name: PropTypes.string,
          inputType: PropTypes.string,
        }),
        values: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string,
        })),
      })),
      images: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      })),
    })),
    seoDescription: PropTypes.string,
    seoTitle: PropTypes.string,
    metadata: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })),
    description: PropTypes.string,
    descriptionJson: PropTypes.string,
  }).isRequired,
};
