import React from 'react';
import { DropdownAttribute } from '../attributes/dropdown';
import { MultiselectAttribute } from '../attributes/multiselect';
import { SelectedAttribute } from '../attributes/selected';

// we build the templates in this function instead of explicitly
// in the template to save on build time
// by having to only loop once

export const separateAttributes = (attrArray, selectedAttributes) => {
  const selected = [];
  const multiselect = [];
  const dropdown = [];

  attrArray.forEach((attr) => {
    if (selectedAttributes.includes(attr.attribute.name)) {
      selected.push(<SelectedAttribute attr={attr} />);
    } else if (attr.values.length > 0) {
      if (attr.attribute.inputType === 'MULTISELECT') {
        multiselect.push(<MultiselectAttribute attr={attr} />);
      } else if (attr.attribute.inputType === 'DROPDOWN' && attr.values[0].name !== 'None') {
        dropdown.push(<DropdownAttribute attr={attr} />);
      }
    }
  });

  return [selected, multiselect, dropdown];
};
