import React, { useState } from 'react';
import {
  Button, Card, Divider, Modal, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FcFaq, FcFeedback } from 'react-icons/all';
import css from './public-metadata-modal.module.less';

const {
  Paragraph,
  Title,
} = Typography;

export const PublicMetadataModal = ({ metadata }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contactsVisible, setContactsVisible] = useState(false);

  return (
    <>
      <Button size="large" type="dashed" onClick={() => setIsModalVisible(true)}>Contact</Button>
      <Modal
        title="Contact"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {contactsVisible
          ? (
            <>
              <Title level={5}>
                Mention code AKOMMO for special offers
              </Title>
              {metadata.map((entry) => (
                <Paragraph strong>
                  {entry.key}
                  {' '}
                  -
                  {' '}
                  {entry.value}
                </Paragraph>
              ))}
            </>
          )

          : (
            <Card
              className={css.secondary}
              hoverable
              onClick={() => setContactsVisible(true)}
            >
              <Card.Meta
                avatar={<FcFeedback />}
                title={<Title level={5}>Contact Directly</Title>}
              />
            </Card>
          )}
        <Divider orientation="center">OR</Divider>
        <Card hoverable bordered={false} className={css.primary}>
          <Link to="/user/favourites/">
            <Card.Meta
              avatar={<FcFaq />}
              title={<Title level={4}>Convenient Contact (recommended)</Title>}
              description="Proceed to the Favourites page and sign in to use a seamless chat with multiple vendors."
            />
          </Link>
        </Card>
      </Modal>
    </>
  );
};

PublicMetadataModal.defaultProps = {
  metadata: [],
};

PublicMetadataModal.propTypes = {
  metadata: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
};
