import React from 'react';
import PropTypes from 'prop-types';
import { Col, Typography } from 'antd';
import { MultiselectAttribute } from './multiselect';

const { Title } = Typography;

export const SelectedAttribute = ({ attr }) => {
  if (attr.attribute.inputType === 'MULTISELECT') {
    return <MultiselectAttribute key={attr.attribute.name} attr={attr} />;
  }
  return (
    <Col flex="1 1 50%" key={attr.attribute.name}>
      <Title level={5}>
        {attr.attribute.name}
        {' - '}
        {attr.values?.[0]?.name}
      </Title>
    </Col>
  );
};

SelectedAttribute.propTypes = {
  attr: PropTypes.shape({
    attribute: PropTypes.shape({
      name: PropTypes.string,
      inputType: PropTypes.string,
    }),
    values: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }).isRequired,
};
